import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import useCurrency from 'hooks/useCurrency';

export default function DebtCard({ analyticData, title, type }) {
  const { t } = useTranslation();
  const { currencyData } = useCurrency();
  
  return (
    <div
      className={`${
        type === 'monthly' ? 'monthly' : 'total'
      } price-item-wrapper col-span-12 sm:col-span-6 md:col-span-4`}
    >
      <div className='price-item-title'>{title}</div>
      <div className='price-item-sum'>
        {analyticData.isLoading || analyticData.isFetching ? (
          <div className='flex my-1'>
            <CircularProgress size={20} color='inherit' />
          </div>
        ) : (
          <div>
            <div>
              UZS:{' '}
              <NumericFormat
                value={
                  type === 'monthly'
                    ? analyticData?.data?.monthsum
                    : analyticData?.data?.totalsum
                }
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={2}
                className='bg-transparent'
                suffix={' UZS'}
              />
            </div>
            <div>
              USD:{' '}
              <NumericFormat
                value={
                  type === 'monthly'
                    ? analyticData?.data?.monthvalute
                    : analyticData?.data?.totalvalute
                }
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={2}
                className='bg-transparent'
                suffix={' $'}
              />
            </div>
            <div>
              {t('common.table.total')}:{' '}
              <NumericFormat
                value={
                  type === 'monthly'
                    ? +analyticData?.data?.monthsum +
                      +analyticData?.data?.monthvalute * currencyData?.sum
                    : +analyticData?.data?.totalsum +
                      +analyticData?.data?.totalvalute * currencyData?.sum
                }
                displayType={'text'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalScale={2}
                className='bg-transparent'
                suffix={' UZS'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
