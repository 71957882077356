import { ButtonBase, Grid } from '@mui/material';
import { Fragment, memo } from 'react';
import { useShowroom } from 'context';
import ShaxmatkaHomeTooltip from '../../../shaxmatka/shaxmatka-parts/tooltips/ShaxmatkaHomeTooltip';

export default memo(function Floor({
  size,
  homes,
  floorNumber,
  index,
  settingsMenuValue = 'square',
}) {
  const [, dispatch] = useShowroom();
  const onClick = (e, id) => {
    document
      ?.querySelector('.showroom-wrapper')
      ?.querySelectorAll('.item-selected')
      ?.forEach((el) => el?.classList?.remove('item-selected'));
    e?.target?.classList?.toggle('item-selected');
    dispatch({ type: 'setHomeId', payload: id });
  };
  return (
    <Fragment>
      <Grid
        container
        rowSpacing={0}
        columns={{
          xs: parseInt(size),
          sm: parseInt(size),
        }}
        className={'sheet-home-row'}
      >
        {index == 0 && (
          <Grid
            item={true}
            sm={1}
            xs={1}
            className='sheet-home-cell !-ml-2.5 !mr-0'
          >
            <ButtonBase
              className='sheet-home-row-floor h-full w-8 text-sm font-medium !cursor-default'
              disableRipple
            >
              {floorNumber}
            </ButtonBase>
          </Grid>
        )}
        {homes.filter((home) => home.stage == floorNumber).length > 0
          ? homes
              .filter((home) => home.stage == floorNumber)
              .map((item) => (
                <Grid
                  item={true}
                  sm={1}
                  xs={1}
                  key={`block-${index}-home-${item.id}`}
                  id={`block-${index}-home-${item.id}`}
                  className={`sheet-home-cell floor-${floorNumber} `}
                >
                  <ShaxmatkaHomeTooltip home={item}>
                    <ButtonBase
                      className={`block-${index}-home home-item status-${
                        item.status
                      }${item.disabled ? ' is-disabled' : ''}`}
                      id={`home-${item.id}`}
                      onClick={(e) => onClick(e, item?.id)}
                    >
                      {settingsMenuValue == 'rooms' ? (
                        !(item.stage && parseInt(item.stage) < 0) ? (
                          item.rooms ? (
                            <div className='pointer-events-none'>
                              {item.rooms}x
                            </div>
                          ) : (
                            ''
                          )
                        ) : (
                          <div className='pointer-events-none'>P</div>
                        )
                      ) : (
                        <div className='pointer-events-none'>{item.number}</div>
                      )}
                    </ButtonBase>
                  </ShaxmatkaHomeTooltip>
                </Grid>
              ))
          : [1].map((item) => (
              <Grid
                item={true}
                sm={1}
                xs={1}
                key={`block-home-empty-${item}`}
                id={`block-home-empty-${item}`}
                className={`sheet-home-cell sheet-home-empty-cell floor-${floorNumber}`}
              >
                <ButtonBase className='home-item is-empty'>
                  <div className='home-item-data'></div>
                </ButtonBase>
              </Grid>
            ))}
      </Grid>
    </Fragment>
  );
});
