/* eslint-disable eqeqeq */
import { Box, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { memo, useState } from 'react';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSearch from 'hooks/useSearch';
import { useLocation } from 'react-router-dom';
import Shaxmatka from './shaxmatka';
import ShowroomHomeDetail from './home-modals/ShowroomHomeDetail';

export default memo(function ShowroomPanel({ tabValue }) {
  const { search } = useLocation();
  const query = useSearch();
  const axiosPrivate = useAxiosPrivate();

  const homesApi = useQuery({
    queryKey: ['/admin/home/padezd/', search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/home/padezd/${query?.get('objectId')}${search}`
      );
      return response.data.data;
    },
    enabled: !!query?.get('objectId'),
    retry: false,
  });

  return (
    <div className='flex w-full min-h-[400px] gap-4'>
      <div
        className={
          'base-border grow bg-white h-full p-5 w-[calc(100%-320px)] basis-[calc(100%-320px)]'
        }
      >
        <div className='w-full'>
          <Shaxmatka
            homes={homesApi?.data}
            isLoading={homesApi?.isLoading || homesApi?.isFetching}
          />
        </div>
      </div>
      <ShowroomHomeDetail />
    </div>
  );
});
